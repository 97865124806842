@mixin breakpoint-mobile-narrow {
    @media (max-width: 449px) { @content; }
}
@mixin breakpoint-mobile-only {
    @media (max-width: $BREAKPOINT_TABLET - 1px) { @content; }
}
@mixin breakpoint-tablet-up {
    @media (min-width: $BREAKPOINT_TABLET) { @content; }
}
@mixin breakpoint-tablet-only {
    @media (min-width: $BREAKPOINT_TABLET) and (max-width: $BREAKPOINT_DESKTOP - 1px) { @content; }
}
@mixin breakpoint-tablet-down {
    @media (max-width: $BREAKPOINT_DESKTOP - 1px) { @content; }
}
@mixin breakpoint-desktop-up {
    @media (min-width: $BREAKPOINT_DESKTOP) { @content; }
}
@mixin breakpoint-masthead-max {
    @media (min-width: $BREAKPOINT_MASTHEAD_MAX) { @content; }
}
@mixin breakpoint-desktop-only {
    @media (min-width: $BREAKPOINT_DESKTOP) and (max-width: $BREAKPOINT_WIDE - 1px) { @content; }
}
@mixin breakpoint-wide-up {
    @media (min-width: $BREAKPOINT_WIDE) { @content; }
}
@mixin RESPONSIVE_WIDTH {
    margin-left: auto;
    margin-right: auto;

    @include breakpoint-mobile-only {
        width: $CONTENT_WIDTH_MOBILE;
    }
    @include breakpoint-tablet-only {
        width: $CONTENT_WIDTH_TABLET;
    }
    @include breakpoint-desktop-only {
        width: $CONTENT_WIDTH_DESKTOP;
    }
    @include breakpoint-wide-up {
        width: $CONTENT_WIDTH_WIDE;
    }
}
@mixin RESPONSIVE_CONTENT_MARGINS {
    @include breakpoint-mobile-only {
        padding-left: $CONTENT_MARGIN_MOBILE;
        padding-right: $CONTENT_MARGIN_MOBILE;
    }
    @include breakpoint-tablet-only {
        padding-left: $CONTENT_MARGIN_TABLET;
        padding-right: $CONTENT_MARGIN_TABLET;
    }
    @include breakpoint-desktop-only {
        padding-left: $CONTENT_MARGIN_DESKTOP;
        padding-right: $CONTENT_MARGIN_DESKTOP;
    }
    @include breakpoint-wide-up {
        padding-left: $CONTENT_MARGIN_WIDE;
        padding-right: $CONTENT_MARGIN_WIDE;
    }
}
@mixin INSET_CONTENT {
    @include breakpoint-desktop-up {
        width: $GRID_DESKTOP_TEN_COLUMNS;
        margin-left: auto;
        margin-right: auto;
    }
}
@mixin LEFT_BLOCK_CONTENT($tablet_width: 50%, $desktop_width: 40%) {
    @include breakpoint-tablet-up {
        width: $tablet_width;
        margin-right: auto;
    }
    @include breakpoint-desktop-up {
        width: $desktop_width;
    }
}
@mixin RIGHT_BLOCK_CONTENT($tablet_width: 50%, $desktop_width: 40%) {
    @include breakpoint-tablet-up {
        width: $tablet_width;
        margin-left: auto;
    }
    @include breakpoint-desktop-up {
        width: $desktop_width;
    }
}
