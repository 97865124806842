// Warframe.com Landing Theme
// Used by 2023 landing page

@import "../style-guide/_variables";
@import "../style-guide/_responsive";
@import "../style-guide/_mixins";
@import "../style-guide/_typography";

$IMAGE_DIR: '/images/landing/';

$COLOR_WARFRAME_GRID_BORDER: #5F553D;
$COLOR_WARFRAME_GRID_TITLE: #A99565;

$COLOR_BORDER_GALLERY: $COLOR_INVERTED_TEXT;
$COLOR_BACKGROUND_THUMBNAIL: rgba(255,255,255,.2);
$COLOR_BACKGROUND_THUMBNAIL_HOVER: rgba(255,255,255,.4);
$COLOR_BACKGROUND_DROPDOWN: #D9D9D9;

#landing {
    background-image: url($IMAGE_DIR + 'bg-main.jpg');
    background-size: 100% auto;
    background-position: top;

    color: $COLOR_INVERTED_TEXT;
}

.NavigationWidget {
    position: fixed;
    z-index: 10;
    text-align: right;

    @include breakpoint-mobile-only {
        top: 0;
        right: 0;
        left: 0;
    }
    @include breakpoint-tablet-up {
        top: $SPACE_LARGER;
        right: $SPACE_LARGER;
    }

    &-grid {
        @include breakpoint-mobile-only {
            display: grid;
            grid-template-columns: 1fr auto;
            align-items: center;
            justify-items: start;
            gap: $SPACE_MEDIUM;
            padding: $SPACE_MEDIUM;
            transition: background 250ms;
        }
    }

    &-container {
        position: relative;
        display: inline-flex;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        width: 96px;
        height: 48px;
        opacity: 0.5;
        transition: opacity 250ms;
        z-index: 1;

        @include breakpoint-mobile-only {
            grid-area: 1 / 2;
        }

        &:hover {
            opacity: 1.0;
        }
    }

    &-outline {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        border: solid 1px $COLOR_INVERTED_TEXT;
        border-radius: $SPACE_LARGE;
        background-color: rgba(0,0,0,.5);
        pointer-events: none;
        transition: all 250ms;
    }
    &-language,
    &-site {
        position: relative;
        display: inline-block;
        width: 48px;
        height: 48px;
        padding: 14px;
        cursor: pointer;
        z-index: 1;
    }
    &-language {
        &:hover {
            &~ .NavigationWidget-outline {
                width: 50%;
            }
        }

        &.is-active {
            .NavigationWidget-languageDropdown {
                display: block;
            }
        }
    }
    &-languageDropdown {
        display: none;
        position: absolute;
        right: 0;
        top: 100%;
        list-style: none;
        margin: 0;
        padding: 0;
        background-color: $COLOR_BACKGROUND_DROPDOWN;
        text-align: left;
    }
    &-optionLink {
        font-weight: bold;
        color: $COLOR_TEXT;
        padding: $SPACE_SMALLER $SPACE_MEDIUM;
        opacity: 0.5;

        &.is-active {
            &:after {
                content: "&middot;";
                margin-left: $SPACE_SMALL;
            }
        }

        &:hover {
            opacity: 1.0;
        }
    }
    &-site {
        &:hover {
            &~ .NavigationWidget-outline {
                left: 50%;
                width: 50%;
            }
        }
    }
    &-icon {
        width: 20px;
        height: 20px;
        filter: invert(1);
    }

    &-cta {
        opacity: 0;
        pointer-events: none;
        transition: opacity 250ms;
        text-align: center;

        @include breakpoint-mobile-only {
            grid-area: 1 / 1;
        }
        @include breakpoint-tablet-up {
            margin-top: $SPACE_MEDIUM;
        }

    }
    &.is-active {
        .NavigationWidget-grid {
            @include breakpoint-mobile-only {
                background-color: rgba(0,0,0,.9);
            }
        }
        .NavigationWidget-cta {
            opacity: 1;
            pointer-events: unset;
        }
    }
}
.NavigationCTAButton {
    @include breakpoint-mobile-only {
        padding: 0 $SPACE_SMALL;
    }
}

.MastheadPlaceholder,
.MastheadSectionMask,
.MastheadSection {
    height: 100vh;
    max-height: 100vh;

    @include breakpoint-wide-up {
        height: 900px;
    }
}
.MastheadSectionMask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}
.MastheadSection {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;

    &.is-hidden {
        display: none;
    }

    .Section-background {
        overflow: hidden;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-image: linear-gradient(to bottom, transparent 33%, rgba(0,0,0,.8) 67%);
            pointer-events: none;

        }
    }
    .Section-content {
        height: 100%;
    }
}
.MastheadVideo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.MastheadContainer {
    box-sizing: border-box;
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    filter: drop-shadow(0 0 6px $COLOR_INVERTED_BACKGROUND);

    @include breakpoint-tablet-up {
        grid-template-rows: minmax(0, 2fr) minmax(0, 3fr);
    }

    &-spacer {
        @include breakpoint-mobile-only {
            height: 60vw;
        }
    }
}
.MastheadLogo {
    &-image {
        width: 80%;
        max-width: 420px;
    }
}
.MastheadDescription {
    width: 90%;
    max-width: 800px;
    margin: 2rem auto 3rem;
    opacity: 0.8;
}
.TopCTAButton {
    @include breakpoint-mobile-only {
        width: 100%;
    }
    @include breakpoint-tablet-up {
        padding: 0 $SPACE_HUGE;
    }
}
.MastheadAccount {
    margin-top: 1rem;
    &-link {
        opacity: 0.8;
        color: $COLOR_INVERTED_TEXT;
        text-decoration: underline;

        &:hover, &:active {
            opacity: 1.0;
            color: $COLOR_INVERTED_TEXT;
        }
    }
}
.MastheadUpperContainer,
.MastheadLowerContainer {
    z-index: 1;
}
.MastheadScrollContainer {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
}
.MastheadScrollArrow {
    pointer-events: none;
    &-icon {
        width: 4rem;
        height: 4rem;
        filter: invert(1) drop-shadow(0 0 6px $COLOR_INVERTED_BACKGROUND);
    }
}

.TitleSection {
    text-align: center;
    /*height: 20vh;*/

    -webkit-mask-image: linear-gradient(to top, transparent, black 25%, black 100%);
    mask-image: linear-gradient(to top, transparent, black 25%, black 100%);

    .Section-content {
        height: 100%;
    }
    .SectionContent {
        height: 100%;
    }
}
.WidescreenSectionBackground {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.TitleSectionBackground {
    background-image: url($IMAGE_DIR + 'bg-title.jpg');
}
.TitleWarframeImage {
    position: absolute;
    top: 50%;
    left: 47.5%;
    transform: translate(-50%, -50%);

    &-image {
        height: 54.44vh;

        @include breakpoint-desktop-up {
            height: 90.74vh;
        }
    }
}

.WarframesSection {
    overflow: hidden;
    margin-top: 72px;
    margin-bottom: 121px;

    @include breakpoint-tablet-down {
        margin-top: 92px;
        margin-bottom: 121px;
    }

    @include breakpoint-mobile-only {
        margin-top: 67px;
        margin-bottom: 47px;
    }

    .Section-wrapper {
        overflow-x: unset;
    }
}
.WarframesTitleBlock {
    text-align: center;

    &-description {
        margin: 1.5rem auto $SPACE_LARGER;
        max-width: 800px;
    }
}

.WarframesGalleryGrid {
    position: relative;

    &-scrollWrapper {
        position: relative;
        padding: $SPACE_LARGE 0 0;

        @include breakpoint-mobile-only {
            padding: $SPACE_LARGE 5vw;
            margin: 0 -5vw;
            overflow-x: scroll;
        }
    }
    &-tileContainer {
        position: relative;
        height: 400px;
        transition: height 250ms;
        padding: 0;
        max-width: 1000px;
        margin: 0 auto;
        z-index: 1;
    }
    &-tiles {
        position: relative;
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: $SPACE_MEDIUM;
        @include breakpoint-mobile-only {
            display: inline-grid;
            grid-template-columns: repeat(3, 288px);
        }
    }
    &-controls {
        position: relative;
        text-align: center;
        padding: $SPACE_LARGER+$SPACE_MEDIUM 0 $SPACE_LARGER;
        max-width: 1000px;
        margin: 0 auto;
    }
    &-outerGuideline {
        position: absolute;
        top: 0;
        height: 50%;
        left: 16%;
        right: 16%;
        border: solid 1px $COLOR_WARFRAME_GRID_BORDER;
        border-top: none;
        @include breakpoint-mobile-only {
            display: none;
        }
    }
    &-innerGuideline {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 0;
        border-left: solid 1px $COLOR_WARFRAME_GRID_BORDER;
        @include breakpoint-mobile-only {
            display: none;
        }
    }
    &-leftNode,
    &-rightNode {
        position: absolute;
        bottom: 0;
        width: $SPACE_MEDIUM;
        height: $SPACE_MEDIUM;
        border: solid 1px $COLOR_WARFRAME_GRID_BORDER;
        border-radius: 50%;
        @include breakpoint-mobile-only {
            display: none;
        }
    }
    &-leftNode {
        left: 0;
        transform: translate(-50%, 50%);
    }
    &-rightNode {
        right: 0;
        transform: translate(50%, 50%);
    }

    &-showMore {
        background: #000000;
        border: 1px solid #5F553D;
        border-radius: 0;

        .Button-label {
            background: radial-gradient(50% 50% at 50% 50%, #A99565 0%, rgba(169, 149, 101, 0.7) 100%);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 700;
            font-size: 28px;
            padding: 5px 10px;
        }
    }
}
.WarframeTile {
    border: solid 1px $COLOR_WARFRAME_GRID_BORDER;
    overflow: visible;
    height: 100%;

    @include breakpoint-mobile-only {
        display: inline-grid;
        width: 270px;
    }

    &-container {
        overflow: visible;
        height: 100%;
        opacity: 0;
        transition: opacity 125ms;
        display: grid;
        grid-template-rows: auto 1fr;
    }

    &.is-active {
        .WarframeTile-container {
            opacity: 1;
        }
    }

    &-image {
        display: block;
        width: 100%;
        margin-top: -$SPACE_LARGE;
        margin-bottom: -$SPACE_LARGE;
        aspect-ratio: 1;
        object-fit: cover;
    }

    &-text {
        position: relative;
        padding: 16px 24px 24px;
        min-height: 14rem;
        background: linear-gradient(to bottom, transparent, $COLOR_INVERTED_BACKGROUND $SPACE_LARGE, $COLOR_INVERTED_BACKGROUND);
        z-index: 1;
    }
    &-name {
        color: transparent;
        background: radial-gradient(50% 50% at 50% 50%, #A99565 0%, rgba(169, 149, 101, 0.7) 100%);
        -webkit-background-clip: text;
        background-clip: text;
        @include TYPOGRAPHY_H3;
    }
    &-description {
        margin-top: $SPACE_SMALL;
        font-size: $FONT_SIZE_SUB;
        line-height: $LINE_HEIGHT_PARAGRAPH_SMALL;
    }
}

.CustomizationSection {
    text-align: center;
    height: 100vh;

    .Section-content {
        height: 100%;
        overflow: hidden;
    }

    &-title {
        &.is-compressed {
            @include TYPOGRAPHY_H3;
        }
        &.is-compressedMore {
            @include TYPOGRAPHY_H4;
        }
    }
    &-description {
        margin: 0 auto;
        width: 90%;
        max-width: 600px;
    }
}
.CustomizationSectionBackground {
    background-image: url($IMAGE_DIR + 'bg-arsenal.jpg');
    -webkit-mask-image: linear-gradient(to top, transparent, black 25%, black 75%, transparent);
    mask-image: linear-gradient(to top, transparent, black 25%, black 75%, transparent);
    filter: brightness(0.5) blur(5px);
    @include breakpoint-desktop-up {
        filter: brightness(0.7) blur(5px);
    }
}
.CustomizationGalleryBlock {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 50vh;
    transform: translate(-50%, -40%);
    aspect-ratio: 967 / 1857;

    @include breakpoint-tablet-up {
        height: 70vh;
    }
    &.is-visible {
        transform: translate(-50%, -50%);
    }
}
.CustomizationGrid {
    position: relative;
    height: 100%;
    width: 100%;

    &-thumbnails {
        position: absolute;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        gap: 16px;

        @include breakpoint-mobile-only {
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            padding-top: 40px;
        }

        @include breakpoint-tablet-up {
            right: 100%;
            top: 0;
            bottom: 0;
            flex-flow: column nowrap;
            gap: 24px;
            padding-right: 80px;
        }
    }
    &-thumbnail {
        position: relative;
        width: 60px;
        height: 60px;
        background-color: $COLOR_BACKGROUND_THUMBNAIL;
        cursor: pointer;

        @include breakpoint-tablet-up {
            width: 100px;
            height: 100px;
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border: solid 1px $COLOR_BORDER_GALLERY;
            pointer-events: none;
        }

        &.is-active {
            &:after {
                border-width: 3px;
            }
        }
        &:hover {
            background-color: $COLOR_BACKGROUND_THUMBNAIL_HOVER;
        }
    }
    &-slides {
        position: relative;
        aspect-ratio: 967 / 1857;
        width: 100%;
        margin: 0 auto;
    }
    &-slide {
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        transition: opacity 250ms;
        filter: drop-shadow(0 0 10px rgba(255,255,255,.5));

        &.is-active {
            opacity: 1;
        }
    }
    &-image {
        width: 100%;
        height: 100%;
        border: none;
        object-fit: cover;
    }
}

.AnimateWhenVisible--reverseVideoZoom {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    max-width: 100%;
    aspect-ratio: 16/9;
    transform: translateX(-50%);
    z-index: 1;
    transition: all 500ms;

    @include breakpoint-wide-up {
        width: 1600px;
        max-width: 1600px;
    }

    .AnimateWhenVisible-content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: all 500ms;
    }

    &.is-visible {
        top: 19px;
        width: 60vw;
        max-width: 960px;
    }
}
.AnimatedVideoPlaceholder {
    text-align: center;

    &-border {
        display: inline-block;
        border: solid 3px $COLOR_WARFRAME_GRID_BORDER;
        padding: 16px;
    }
    &-matte {
        display: inline-block;
        width: 60vw;
        max-width: 960px;
        aspect-ratio: 16/9;
    }
}
.AnimatedVideoSection {
    position: relative;
    height: 0;
    overflow: visible;
    z-index: 1;
    pointer-events: none;

    &-inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        aspect-ratio: 16/9;
        overflow: hidden;

        @include breakpoint-wide-up {
            right: auto;
            left: 50%;
            width: 1600px;
            transform: translateX(-50%);

            @include MASK_WIDESCREEN_FADE;
        }
    }
}
.AnimatedVideo {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
}

.ParkourSection {
    margin-bottom: $SPACE_HUGE;

    .Section-wrapper {
        overflow: hidden;
    }
    .Section-content {
        text-align: center;
    }

    &-description {
        width: 90%;
        max-width: 800px;
        margin: 0 auto;
    }
}
.ParkourTitleBlock {
    margin: $SPACE_LARGER 0;
}

.WeaponsSection {
    &-title {
        text-align: center;
    }
    &-description {
        text-align: center;
        max-width: 800px;
        margin: 0 auto;
    }
}
.WeaponsGrid {
    margin-bottom: $SPACE_HUGE;

    &-wrapper {
        margin-top: $SPACE_THIN;
        margin-bottom: $SPACE_HUGE;
        text-align: center;
    }

    &-container {
        display: inline-grid;
        @include breakpoint-mobile-only {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(3, minmax(0, 1fr));
            gap: 32px;
        }
        @include breakpoint-tablet-only {
            margin: 0 auto;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-template-rows: repeat(2, minmax(0, 1fr));
            gap: 48px;
        }
        @include breakpoint-desktop-up {
            margin: 0 auto;
            grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr) auto minmax(0, 1fr);
            grid-template-rows: 1fr;
            justify-items: center;
            align-items: center;
            gap: 0;
        }
    }

    &-divider {
        display: none;
        height: 1px;
        width: 120px;
        background-color: $COLOR_WARFRAME_GRID_BORDER;

        @include breakpoint-desktop-up {
            display: unset;
        }
    }
}

.WeaponsGridTile {
    position: relative;
    border: solid 1px $COLOR_WARFRAME_GRID_BORDER;
    background: $COLOR_INVERTED_BACKGROUND;

    @include breakpoint-mobile-only {
        width: 80vw;
        aspect-ratio: 2.0;
    }
    @include breakpoint-tablet-up {
        width: 275px;
        aspect-ratio: 0.72;
    }

    &-text,
    &-slide {
        z-index: 1;
    }
    &-text {
        position: absolute;
        left: $SPACE_MEDIUM;
        right: $SPACE_MEDIUM;
        text-align: center;

        @include breakpoint-mobile-only {
            left: auto;
            bottom: $SPACE_MEDIUM;
            white-space: nowrap;
        }
        @include breakpoint-tablet-only {
            bottom: $SPACE_MEDIUM;
        }
        @include breakpoint-desktop-up {
            top: 110%;
            left: 0;
            right: 0;
        }
    }
    &-title {
        font-weight: bold;
        text-transform: uppercase;
        color: transparent;
        background: radial-gradient(50% 50% at 50% 50%, #A99565 0%, rgba(169, 149, 101, 0.7) 100%);
        -webkit-background-clip: text;
        background-clip: text;

        @include breakpoint-desktop-up {
            font-size: $FONT_SIZE_H4_DESKTOP;
        }
    }
    &-subtitle {
        display: none;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: radial-gradient($COLOR_WARFRAME_GRID_BORDER, $COLOR_INVERTED_BACKGROUND);
        opacity: 0.5;
        pointer-events: none;
    }

    &--melee {
        @include breakpoint-tablet-only {
            grid-column: 1 / span 2;
            width: 598px;
            aspect-ratio: 3.02;

            .WeaponsGridTile-text {
                left: auto;
                white-space: nowrap;
            }
        }
    }
}

@keyframes WeaponSlideIn {
    from {
        -webkit-mask-position: left;
        mask-position: left;
    }
    to {
        -webkit-mask-position: right;
        mask-position: right;
    }
}
@keyframes WeaponSlideOut {
    from {
        -webkit-mask-position: left;
        mask-position: left;
    }
    to {
        -webkit-mask-position: right;
        mask-position: right;
    }
}
.WeaponSlideshow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120%;
    aspect-ratio: 1;

    &-slide {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        -webkit-mask-origin: border;
        mask-origin: border-box;
        -webkit-mask-size: 300% 100%;
        mask-size: 300% 100%;
        -webkit-mask-position: left;
        mask-position: left;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;

        &.is-on {
            opacity: 1;
            -webkit-mask-image: linear-gradient(45deg, transparent, transparent 50%, black 51%, black);
            mask-image: linear-gradient(45deg, transparent, transparent 50%, black 51%, black);
            animation-name: WeaponSlideIn;
            animation-duration: 1s;
            animation-fill-mode: both;
        }
        &.is-off {
            opacity: 1;
            -webkit-mask-image: linear-gradient(45deg, black, black 50%, transparent 51%, transparent);
            mask-image: linear-gradient(45deg, black, black 50%, transparent 51%, transparent);
            animation-name: WeaponSlideOut;
            animation-duration: 1s;
            animation-fill-mode: both;
        }
    }
    &-image {
        width: 100%;
        height: 100%;
        border: none;
    }
    @include breakpoint-mobile-only {
        width: 40%;
    }

    &--melee {
        @include breakpoint-tablet-only {
            width: 50%;
        }
    }
}


.SystemSection {
    .Section-wrapper {
        overflow: hidden;
    }
    .Section-content {
        text-align: center;
    }

    &-description {
        width: 90%;
        max-width: 800px;
        margin: 0 auto;
    }
}
.SystemTitleBlock {
    margin-top: $SPACE_LARGER;
    margin-bottom: $SPACE_HUGE;
}

.SquadSectionBackground {
    background-image: url($IMAGE_DIR + 'bg-squad.jpg');
    -webkit-mask-image: linear-gradient(to top, transparent, black 25%, black 100%);
    mask-image: linear-gradient(to top, transparent, black 25%, black 100%);
}

.PathTitleBlock {
    padding: $SPACE_LARGER 0 $SPACE_HUGE;
}

.SquadSection {
    text-align: center;
    aspect-ratio: 16/9;

    background-image: linear-gradient(to top, black, transparent 25%, transparent 100%);

    @include breakpoint-wide-up {
        aspect-ratio: auto;
        height: 900px;
    }

    .Section-background {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-image: linear-gradient(to top, black, transparent 25%, transparent);;
            pointer-events: none;
        }
    }

    .Section-content {
        height: 100%;
        overflow: hidden;
    }

    &-imageContainer {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    &-image {
        width: 60vw;
        max-width: 960px;
    }
}
.SquadSectionBackground {
    background-image: url($IMAGE_DIR + 'bg-squad.jpg');
    -webkit-mask-image: linear-gradient(to top, transparent, black 25%, black 100%);
    mask-image: linear-gradient(to top, transparent, black 25%, black 100%);
}
.SquadTextSection {
    .Section-background {
        background-color: $COLOR_INVERTED_BACKGROUND;
    }
    .Section-content {
        padding-bottom: 10vw;
        text-align: center;
    }
    &-title {
    }
    &-description {
        margin: 0 auto;
        width: 90%;
        max-width: 800px;
    }
}


// Demo Callout Section
$COLOR_DOWNLOAD_TITLE: #F6E3AB;
$IMAGE_DIR: '/images/game/download/';
@import "../style-guide/_variables";
@import "../style-guide/_responsive";
@import "../style-guide/_mixins";
@import "../style-guide/_typography";

// 1999 DEMO SECTION

#demo-callout{
    --color-text: #{$COLOR_INVERTED_TEXT};
    --color-title: #{$COLOR_DOWNLOAD_TITLE};
    color: #{$COLOR_INVERTED_TEXT};
    background-color: $COLOR_BLACK;
}
.demoBackground{
    @include FULL_COVER;
    background: top / cover no-repeat url(#{$IMAGE_DIR}1999-bg-callout-motorcycle.jpg);

    @include FULL_COVER_WIDE_CAP;
    @include breakpoint-wide-up {
        mask-image: linear-gradient(to right, transparent, black 10%, black 90%, transparent);
    }

    @include breakpoint-mobile-only{

        background-position: -300px;

        height: 60%;

        &:after {
            content: "";
            @include FULL_COVER;
            background: linear-gradient(to top, black, transparent 30%);
        }
    }
    
}

.demoGrid {
    position: relative;
    padding: $SPACE_LARGE $SPACE_LARGE 80px;
    min-height: 680px;
    max-width: 600px;
    margin: 0 auto;
    margin-right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    @include breakpoint-tablet-up {
        max-height: 810px;
    }

    @include breakpoint-desktop-up {
        height: 810px;
    }

    &-title {
        color: var(--color-title);
        @include TYPOGRAPHY_H1_ALTERNATE;
    }
    &-description {
        font-size: $FONT_SIZE_PARAGRAPH_LARGE;
        margin-block-end: 1rem !important;
        margin-block-start: 1rem !important;
    }
    .ButtonGroup{
        margin-block-start: 1.5rem;
        
        .Button-label{
            letter-spacing: normal;
        }
    }
    &-requirements {
        margin-top: 1rem;
    }

    // Mobile/tablet adjustment
    @include breakpoint-mobile-only{
        justify-content: flex-end;
    }

    @include breakpoint-tablet-only{
        padding-right: 0px;
        padding-left: 0px;
        width: 345px;
        
    }
}

.bottom-line{
    width: 100%;
    height: 1px;
    background-color: white;
}

// Fix animation bug
.Section-wrapper{
    height: 100%;
}
